import { Injectable } from "@angular/core";
import { CashRegisterService } from "@app-cfs/shared/services/cashregister.service";
import { CashRegisterStatuses, CashRegisterStatusPostDto } from "cfs-communication-pack";
import { Subject, timer } from "rxjs";
import { startWith, switchMap, take, tap } from "rxjs/operators";
import { SettingsHandlerService } from "@app-cfs/shared/services/settings-handler.service";
import { ConnectionCodeService } from "@app-cfs/shared/services/connection-code.service";
import { ConsoleLogHandlerService } from "@app-cfs/shared/services/console-log-handler.service";

@Injectable({
  providedIn: "root"
})
// todo: replace to common project after data and api Services will be moved to common project
export class StatusLoggerService {
  private readonly logIntervalMs = 60_000;

  private resetInterval$ = new Subject<void>();

  constructor(
    private cashRegisterService: CashRegisterService,
    private settingsService: SettingsHandlerService,
    private connectionCodeService: ConnectionCodeService,
    private logService: ConsoleLogHandlerService
  ) {}

  runInterval() {
    this.logService.log("startLoggingInterval");
    return this.resetInterval$.pipe(
      startWith(0),
      switchMap(() => timer(0, this.logIntervalMs)),
      tap((v) => this.logOnlineStatusOnInterval(v))
    );
  }

  logCarouselRefresh(cashRegisterId: number, message = ""): void {
    this.resetInterval$.next();
    this.logStatus(cashRegisterId, CashRegisterStatuses.CarouselRefresh, message);
  }

  logError(cashRegisterId: number, message: string): void {
    this.resetInterval$.next();
    this.logStatus(cashRegisterId, CashRegisterStatuses.Error, message);
  }

  private logOnlineStatusOnInterval(value: number) {
    this.logService.log("logStatusOnInterval: ", value);
    this.logStatus(this.settingsService.cashRegister, CashRegisterStatuses.Online);
  }

  private logStatus(cashRegisterId: number, status: CashRegisterStatuses, message = ""): void {
    if (this.connectionCodeService.paired) {
      const postDto: CashRegisterStatusPostDto = { status, message };

      this.cashRegisterService.setCashRegisterStatus(cashRegisterId, postDto).pipe(take(1)).subscribe();
    }
  }
}
