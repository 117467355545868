import { Injectable } from "@angular/core";
import { LocalStorageService } from "@app-cfs/shared/services";
import { TemplateActivity, TemplateActivityPostDto } from "./../models/activity-log";
import { StorageKeys } from "./../models/storage-keys";
import { SettingsHandlerService } from "./settings-handler.service";

@Injectable({
  providedIn: "root"
})
export class TemplateActivityService {
  static readonly syncTimeout = 1000 * 60 * 10; // 10min

  constructor(private storage: LocalStorageService, private settingsHandler: SettingsHandlerService) {}

  storeEvent(event: TemplateActivity): void {
    const { locationId, cashRegister } = this.settingsHandler;
    const list: TemplateActivityPostDto[] = this.getEventList();
    const activityItem: TemplateActivityPostDto = {
      ...event,
      locationId
    };

    if (activityItem.locationId) {
      if (cashRegister) {
        activityItem.cashRegisterId = cashRegister;
      }

      list.push(activityItem);

      const listAsString = JSON.stringify(list);

      this.storage.setItem(StorageKeys.templateActivityEventListStorageKey, listAsString);
    }
  }

  getEventList(): TemplateActivityPostDto[] {
    const listAsString = this.storage.getItem(StorageKeys.templateActivityEventListStorageKey);
    let list = [];

    if (listAsString) {
      try {
        list = JSON.parse(listAsString);
      } catch (e) {
        console.error(e);
      }
    }

    return list;
  }

  clearEventList(): void {
    this.storage.setItem(StorageKeys.templateActivityEventListStorageKey, JSON.stringify([]));
  }
}
