import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ResetUserPinByEmailPostDto, ResetUserPinResponseDto } from "../models";
import { ApiService } from "clearline-api";

@Injectable({
  providedIn: "root"
})
export class UserService {
  private serviceUrl = "user";

  constructor(private http: ApiService) {}

  tokenByPin(pin: string): Observable<string> {
    return this.http.post(`${this.serviceUrl}/tokenByPin`, { pin });
  }
  resetUserPinByEmail(dto: ResetUserPinByEmailPostDto): Observable<ResetUserPinResponseDto> {
    return this.http.post(`${this.serviceUrl}/pin/reset/email/withConfirmation`, dto);
  }
}
