import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "clearline-api";
import { Observable } from "rxjs";
import { HtmlTemplate, Template } from "../models";

@Injectable({ providedIn: "root" })
export class TemplateService {
  constructor(private http: ApiService) {}

  serviceUrl = "template";

  getCfsThirdPartyTemplateList(locationId: number, cashRegisterId?: number): Observable<Template[]> {
    let params = new HttpParams();

    if (cashRegisterId) {
      params = params.append("cashRegisterId", `${cashRegisterId}`);
    }

    return this.http.get(`${this.serviceUrl}/cfs/${locationId}?${params.toString()}`);
  }

  getAccountTemplate(locationId: number, accountTemplateId: number, cashRegisterId?: number): Observable<Template[]> {
    let params = new HttpParams();

    if (accountTemplateId) {
      params = params.append("accountTemplateId", `${accountTemplateId}`);
    }

    if (cashRegisterId) {
      params = params.append("cashRegisterId", `${cashRegisterId}`);
    }

    return this.http.get(`${this.serviceUrl}/cfs/${locationId}?${params.toString()}`);
  }

  getCfsItems(locationId: number, cashRegisterId: number): Observable<Template[]> {
    return this.http.get(`${this.serviceUrl}/cfs/${locationId}?cashRegisterId=${cashRegisterId}`);
  }

  getMAppTemplate(
    widgetId: number,
    locationId: number,
    cashRegisterId: number,
    shortLink?: string,
    sessionId?: string
  ): Observable<Template> {
    return this.http.get(
      `${this.serviceUrl}/cfs/${locationId}/widget/${widgetId}?cashRegisterId=${cashRegisterId}&sessionId=${sessionId}&shortLink=${shortLink}`
    );
  }

  getContentByUrl(url: string): Observable<string> {
    return this.http.getTextByFullUrl(url);
  }

  getAccountHtmlTemplates(locationId: number): Observable<HtmlTemplate[]> {
    return this.http.get<HtmlTemplate[]>(`templateNew/generated/location/${locationId}`);
  }
}
