import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HtmlTemplate, Template } from "../models";
import { SettingsHandlerService } from "./settings-handler.service";
import { TemplateService } from "@app-cfs/shared/services/template.service";
import { map, tap } from "rxjs/operators";
import { ForceCarouselSlideInfo } from "@app-cfs/shared/services/carousel.service";

@Injectable({ providedIn: "root" })
export class CfsTemplateService {
  constructor(private templateService: TemplateService, private settingsHandler: SettingsHandlerService) {}

  getForceTemplateList(slideInfo: ForceCarouselSlideInfo): Observable<Template[]> {
    const { accountTemplateId, widgetId, sessionId, shortLink } = slideInfo || {};

    if (accountTemplateId) {
      return this.getAccountTemplate(accountTemplateId);
    } else if (widgetId) {
      return this.getMAppTemplate(widgetId, shortLink, sessionId).pipe(map((template: Template) => (template ? [template] : [])));
    }

    return of([]);
  }

  getCfsTemplatesByLocationId(locationId: number, cashRegisterId: number): Observable<(Template | HtmlTemplate)[]> {
    // old flow
    return this.getCfsItemsByLocationId(locationId, cashRegisterId).pipe(tap((templates) => this.settingsHandler.saveTemplates(templates)));

    // new flow
    // return this.getAccountHtmlTemplates(locationId).pipe(tap((templates) => this.settingsHandler.saveTemplates(templates)));
  }

  private getAccountTemplate(accountTemplateId: number): Observable<Template[]> {
    return this.templateService.getAccountTemplate(this.settingsHandler.locationId, accountTemplateId);
  }

  private getMAppTemplate(widgetId: number, shortLink?: string, sessionId?: string): Observable<Template> {
    return this.templateService.getMAppTemplate(
      widgetId,
      this.settingsHandler.locationId,
      this.settingsHandler.cashRegister,
      shortLink,
      sessionId
    );
  }

  private getCfsItemsByLocationId(locationId: number, cashRegisterId: number): Observable<Template[]> {
    return this.templateService
      .getCfsItems(locationId, cashRegisterId)
      .pipe(map((templates: Template[]) => (templates?.length ? templates : [])));
  }
}
