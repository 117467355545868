import { Injectable } from "@angular/core";
import { StorageKeys } from "../models/storage-keys";

import { LocalStorageService } from "../../core/services";
import { CashRegisterUtilsService, ConnectionStatus } from "cfs-communication-pack";

@Injectable({
  providedIn: "root"
})
export class ConnectionCodeService {
  constructor(private localStorage: LocalStorageService, private utils: CashRegisterUtilsService) {}

  get connectionCode(): string {
    let code: string = this.getConnectionCodeFromStorage();

    if (code) {
      return code;
    }

    code = this.utils.getNewConnectionCode();

    this.setConnectionCodeToStorage(code);

    return code;
  }

  get paired(): boolean {
    // oldPaired key is deprecated and temporary, actual one is paired now: we use oldPaired in order of old connected cfs not be broken
    return this.localStorage.getItem(StorageKeys.paired) || this.localStorage.getItem(StorageKeys.oldPaired);
  }

  get connectionStatus(): ConnectionStatus {
    return this.paired ? ConnectionStatus.PAIRED : ConnectionStatus.NOT_PAIRED;
  }

  get pairingDate(): number | undefined {
    const dateString = this.localStorage.getItem(StorageKeys.statusDate);

    return dateString ? +dateString : undefined;
  }

  generateConnectionCode(): string {
    return this.utils.getNewConnectionCode();
  }

  setConnectionCode(code: string): void {
    this.setConnectionCodeToStorage(code);
  }

  setPaired() {
    this.localStorage.setItem(StorageKeys.paired, "true");
    this.setPairingDate();
  }

  setPairingDate(): void {
    this.localStorage.setItem(StorageKeys.statusDate, new Date().getTime().toString());
  }

  private setConnectionCodeToStorage(code: string): void {
    this.localStorage.setItem(StorageKeys.connectionCode, code);
  }

  private getConnectionCodeFromStorage(): string {
    return this.localStorage.getItem(StorageKeys.connectionCode);
  }
}
