import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, debounceTime, delay, filter, map, mergeMap, tap } from "rxjs/operators";
import { AuthService } from "../../core/services";
import {
  CashRegister,
  CFSConnectionMessage,
  CFSConnectionSettings,
  CFSMessagingService,
  CFSPairingState,
  ConnectionStatus,
  NetworkStatus
} from "cfs-communication-pack";
import { ConnectionCodeService } from "./connection-code.service";
import { SettingsHandlerService } from "./settings-handler.service";
import { ToastService } from "@app-cfs/shared/components";
import { TranslocoService } from "@ngneat/transloco";
import * as Pubnub from "pubnub";
import { CfsTemplateService } from "@app-cfs/shared/services/cfs-template.service";
import { ConsoleLogHandlerService } from "@app-cfs/shared/services/console-log-handler.service";
import { StatusLoggerService } from "@app-cfs/shared/services/status-logger.service";

@Injectable({
  providedIn: "root"
})
export class CommunicationManagerService {
  constructor(
    public messagingSvc: CFSMessagingService,
    private connectionCodeService: ConnectionCodeService,
    private authSvc: AuthService,
    private settingsHandler: SettingsHandlerService,
    private cfsTemplateService: CfsTemplateService,
    private toastService: ToastService,
    private translateService: TranslocoService,
    private consoleLogHandler: ConsoleLogHandlerService,
    private statusLoggerService: StatusLoggerService
  ) {}

  private readonly messagingReadyDelay = 2000;
  private _settings: CFSConnectionSettings | undefined;

  onSuccessPairing$ = this.messagingSvc.pairing$.pipe(
    filter((message: CFSConnectionMessage) => {
      this.consoleLogHandler.log("pairing before filter ", message);
      return !!(message && this.canBePaired(message) && message.settings && this.doesSettingsSpecified(message.settings));
    }),
    tap((message: CFSConnectionMessage) => {
      const settings: CFSConnectionSettings = message.settings as CFSConnectionSettings;
      this.consoleLogHandler.log("pairing", settings);
      this.messagingSvc.setStatusConnected(this.connectionCodeService.connectionCode, settings).then((state: CFSPairingState | null) => {
        this.messagingSvc.confirmPairing(this.connectionCodeService.connectionCode, this.settingsHandler.cashRegister, state);
        this.toastService.success(this.translateService.translateObject("connection.notifications.paired"));
        this.messagingSvc.finishPairing();
      });
    })
  );
  isMessagingReady$ = this.messagingSvc.isMessagingReady$.pipe(
    filter((isReady: boolean) => isReady),
    delay(this.messagingReadyDelay)
  );

  runLogErrorCircle(): Observable<string> {
    return this.messagingSvc.logError$.pipe(
      tap((message: string) => {
        this.statusLoggerService.logError(this.settingsHandler.cashRegister, message);
      })
    );
  }

  networkUp(): Observable<null> {
    // TODO: remove (we not use network up event), or there could be network up handling
    return this.messagingSvc.networkUp$;
  }

  networkDown(): Observable<null> {
    // TODO: remove (we not use network down event), or there could be network down handling
    return this.messagingSvc.networkDown$;
  }

  init(): Observable<CFSConnectionMessage> {
    this.messagingSvc.init(this.connectionCodeService.connectionCode, this.connectionCodeService.paired);
    this.updateState(this.connectionCodeService.connectionCode);
    this.consoleLogHandler.log("subscribedChannels: ", this.messagingSvc.getSubscribedChannels()); // do not remove, is needed to see all subscriptions

    return this.messagingSvc.status$.pipe(
      filter((message: CFSConnectionMessage) => {
        return !!(message.settings && message.status === ConnectionStatus.PAIRED && this.isMessageValid(message));
      }),
      map((m) => this.trySaveSettings(m)),
      mergeMap((message) => this.tryLoginOnConnected(message)),
      mergeMap((m) => this.tryLoadTemplateOnConnected(m))
    );
  }

  shareUpdateCFSCarousel(): Observable<CFSConnectionMessage> {
    return this.messagingSvc.updateCarousel$.pipe(
      filter((message: CFSConnectionMessage) => !!(message?.settings && this.doesSettingsSpecified(message.settings))),
      tap((message: CFSConnectionMessage) => {
        const settings: CFSConnectionSettings = message.settings as CFSConnectionSettings;

        this.messagingSvc.setStatusConnected(this.connectionCodeService.connectionCode, settings);
        this.statusLoggerService.logCarouselRefresh(this.settingsHandler.cashRegister);
      })
    );
  }

  returnStatus(): Observable<ConnectionStatus> {
    return this.messagingSvc.returnStatus$.pipe(
      delay(200),
      map(() => {
        const status: ConnectionStatus = this.connectionCodeService.connectionStatus;

        this.updateState(this.connectionCodeService.connectionCode);

        return status;
      })
    );
  }

  private updateState(code: string, clear = false): Promise<Pubnub.SetStateResponse> {
    const status: ConnectionStatus = clear ? ConnectionStatus.NOT_PAIRED : this.connectionCodeService.connectionStatus;
    const networkStatus: NetworkStatus = clear ? NetworkStatus.OFFLINE : NetworkStatus.ONLINE;
    const pairingDate: number | undefined = this.connectionCodeService.pairingDate;
    const state: CFSPairingState = {
      networkStatus,
      cfsCode: code,
      status,
      pairingDate
    };

    if (status === ConnectionStatus.PAIRED) {
      state.cashRegisterId = this.settingsHandler.cashRegister;
      state.locationId = this.settingsHandler.locationId;
      state.resetOthers = true;
    }

    this.consoleLogHandler.log("set STATE:", state);
    return this.messagingSvc.setState(code, state);
  }

  onResetCFS(): Observable<CFSConnectionMessage> {
    return this.messagingSvc.resetCFS$.pipe(
      tap(() => {
        this.consoleLogHandler.log("resetCFS");
        this.settingsHandler.startReset();
        location.reload();
      })
    );
  }

  finishReset(): void {
    const isRefreshInProgress: boolean = this.settingsHandler.isResetInProgress();
    this.consoleLogHandler.log(`isRefreshInProgress: ${isRefreshInProgress}`);

    if (isRefreshInProgress) {
      this.settingsHandler.finishReset();
      this.messagingSvc.confirmReset(this.connectionCodeService.connectionCode, this.settingsHandler.cashRegister);
    }
  }

  connectionChecked(): Observable<CFSConnectionMessage> {
    return this.messagingSvc.status$.pipe(filter((message: CFSConnectionMessage) => this.isMessageValid(message)));
  }

  onQrCodeShared(): Observable<CFSConnectionMessage> {
    return this.messagingSvc.showQrCode$;
  }

  onQrCodeScan(): Observable<CFSConnectionMessage> {
    return this.messagingSvc.scanQrCode$.pipe(filter((message: CFSConnectionMessage) => this.doesSettingsMatch(message?.settings)));
  }

  onQrCodeShown(): Observable<CFSConnectionMessage> {
    return this.messagingSvc.showQrCode$;
  }

  onQrCodeHidden(): Observable<CFSConnectionMessage> {
    return this.messagingSvc.hideQrCode$;
  }

  onQrCodeScanned(): Observable<CFSConnectionMessage> {
    return this.messagingSvc.qrCodeScanned$;
  }

  forceDisplayTemplate(): Observable<CFSConnectionMessage> {
    return this.messagingSvc.forceDisplayTemplate$;
  }

  onUpdateCfsMenu(): Observable<CFSConnectionMessage> {
    return this.messagingSvc.updateCfsMenu$;
  }

  updateCarouselOnNewCircle(): Observable<CFSConnectionMessage> {
    const debounceDelay = 1000;

    return this.messagingSvc.updateCarouselOnNewCircle$.pipe(
      debounceTime(debounceDelay),
      mergeMap((message) => this.tryLoginOnConnected(message)),
      mergeMap(() => {
        return this.tryLoadTemplateOnConnected({ settings: this._settings });
      })
    );
  }

  sendScanQrCodeResult(result: string) {
    this.messagingSvc.publishScannedQrCodeResult(result, this.connectionCodeService.connectionCode);
  }

  sendHideQrCodeMessage(sessionId: string) {
    this.messagingSvc.publishHideQrCodeMessage(sessionId, this.connectionCodeService.connectionCode);
  }

  closeConnection() {
    this.updateState(this.connectionCodeService.connectionCode, true);
    this.messagingSvc.destroyMessages();
  }

  runAutoConnection(id: number, locationId: number, cfsCode: string): Observable<boolean> {
    this.settingsHandler.clearAllStorage();

    this.connectionCodeService.setConnectionCode(cfsCode);
    this.connectionCodeService.setPaired();
    this.settingsHandler.saveCashRegister(id);
    this.settingsHandler.saveLocation(locationId);

    return this.authSvc.login().pipe(
      mergeMap(() => this.cfsTemplateService.getCfsTemplatesByLocationId(locationId, id)),
      map(() => true),
      catchError(() => of(false))
    );
    // Note: the next step should be the redirect to root or page reloading
  }

  isCashRegisterValidToConnect(cashRegister: CashRegister): boolean {
    if (!cashRegister) {
      return false;
    }

    const { id, locationId } = cashRegister;

    return !!id && !!locationId;
  }

  private canBePaired(message: CFSConnectionMessage): boolean {
    const { connectedCode } = message;

    return connectedCode === this.connectionCodeService.connectionCode;
  }

  private isMessageValid(message: CFSConnectionMessage): boolean {
    const cashRegister: number = this.settingsHandler.cashRegister;

    if (!message || !message.settings?.hasOwnProperty("cashRegisterId") || !cashRegister) return true;

    return this.doesSettingsSpecified(message.settings);
  }

  private doesSettingsSpecified(settings: CFSConnectionSettings): boolean {
    const { locationId, cashRegisterId } = settings;

    return locationId !== undefined && locationId >= 0 && cashRegisterId !== undefined && cashRegisterId >= 0;
  }

  private doesSettingsMatch(settings: CFSConnectionSettings | undefined): boolean {
    if (!settings) {
      return false;
    }

    const pairedCashRegisterId: number = this.settingsHandler.cashRegister;
    const pairedLocationId: number = this.settingsHandler.locationId;
    const { locationId, cashRegisterId } = settings;

    return !!(pairedCashRegisterId && pairedLocationId) && cashRegisterId === pairedCashRegisterId && locationId === pairedLocationId;
  }

  private trySaveSettings(message: CFSConnectionMessage): CFSConnectionMessage {
    if (message.settings) {
      this.consoleLogHandler.log("trySaveSettings", message.settings);
      const settings = message.settings;
      const code: string = this.connectionCodeService.connectionCode;
      this._settings = settings;

      this.connectionCodeService.setPaired();
      this.settingsHandler.saveSettings(settings);

      this.updateState(code).then(() => {
        if (settings.resolve) {
          this.messagingSvc.getState(code).then((resolve: Pubnub.GetStateResponse) => {
            const channel = this.messagingSvc.getReturnStatusChannel(code);
            const state: CFSPairingState = resolve.channels[channel];

            settings.resolve(state);
          });
        }
      });
    }

    return message;
  }

  private tryLoadTemplateOnConnected(message: CFSConnectionMessage): Observable<CFSConnectionMessage> {
    const settings: CFSConnectionSettings | undefined = message?.settings;

    const locationId: number | undefined = settings ? settings.locationId : this.settingsHandler.locationId;
    const cashRegisterId: number | undefined = settings ? settings.cashRegisterId : this.settingsHandler.cashRegister;

    if (locationId && cashRegisterId) {
      return this.cfsTemplateService.getCfsTemplatesByLocationId(locationId, cashRegisterId).pipe(
        map((_) => message),
        catchError(() => of(message))
      );
    }

    return of(message);
  }

  private tryLoginOnConnected(message: CFSConnectionMessage): Observable<CFSConnectionMessage> {
    return this.authSvc.login().pipe(
      map((_) => message),
      catchError(() => of(message))
    );
  }
}
