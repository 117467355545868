import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "clearline-api";
import { Observable } from "rxjs";
import { DigitalAsset } from "../models";

@Injectable({
  providedIn: "root"
})
export class DigitalAssetService {
  constructor(private http: ApiService, public https: HttpClient) {}

  private serviceUrl = "asset";

  public getAll(): Observable<DigitalAsset[]> {
    return this.http.get(`${this.serviceUrl}`);
  }

  public getAsset(id: number): Observable<DigitalAsset> {
    return this.http.get(`${this.serviceUrl}/${id}`);
  }
}
