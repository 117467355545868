<iframe
  *ngIf="iframeUrl"
  #iframe
  class="w-100 h-100"
  name="contentiframe"
  frameborder="0"
  allow="camera"
  [src]="iframeUrl"
  (load)="onLoadHandler(iframe)">
</iframe>
