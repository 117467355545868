import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ScreenSizeService {
  isLowResolutionScreen(): boolean {
    return this.isItTV(navigator.userAgent);
  }

  private isItTV(userAgent: string): boolean {
    const match = userAgent.match(/Android\s(.+?)\sBuild/);

    return match ? match[1].includes(" TV ") : false;
  }
}
