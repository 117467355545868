import { Injectable } from "@angular/core";
import { ApiService } from "clearline-api";
import { Observable } from "rxjs";

export interface DeviceBlockedInfoPostDto {
  duration?: number;
  terminalId: string;
  tries: number;
  firstAttemptTime: string;
  lastAttemptTime: string;
}

@Injectable({
  providedIn: "root"
})
export class NotificationService {
  private serviceUrl = "Notification";

  constructor(private http: ApiService) {}

  addDeviceBlockedNotification(info: DeviceBlockedInfoPostDto): Observable<any> {
    return this.http.post(`${this.serviceUrl}/terminal/blocked`, info);
  }
}
