import { Injectable } from "@angular/core";
import { AccountService } from "@app-cfs/core/services";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { Template } from "../models";
import { TemplateService } from "./template.service";
import { TemplatesService } from "./templates.service";

export interface ThirdPartyGetTemplateListOptions {
  locationId: number;
  token: string;
  accountTemplateId?: number;
  cashRegisterId?: number;
}

@Injectable({
  providedIn: "root"
})
export class ThirdPartyCarouselService {
  constructor(
    private templateService: TemplateService,
    private accountService: AccountService,
    private templatesService: TemplatesService
  ) {}

  getTemplateList(options: ThirdPartyGetTemplateListOptions): Observable<Template[]> {
    const { locationId, token, accountTemplateId, cashRegisterId } = options;

    this.accountService.setAccessToken(token);

    const request$ = accountTemplateId
      ? this.templateService.getAccountTemplate(locationId, accountTemplateId, cashRegisterId)
      : this.templateService.getCfsThirdPartyTemplateList(locationId, cashRegisterId);

    return request$.pipe(switchMap((list) => this.templatesService.getPreparedTemplates$(list)));
  }
}
