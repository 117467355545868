import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { take } from "rxjs/operators";
import { TranslocoService } from "@ngneat/transloco";

@Injectable({
  providedIn: "root"
})
export class TranslationService {
  constructor(private translateService: TranslocoService) {
    this.translateService
      .selectTranslate("")
      .pipe(take(1))
      .subscribe(() => {
        // Translation load attempt finished, no meter succeed or not.
        this.ready$.next();
        this.ready$.complete();
      });
  }

  ready$ = new ReplaySubject<void>(1);
}
