import { Injectable } from "@angular/core";
import { ApiService } from "clearline-api";
import { Observable } from "rxjs";
import { Brand } from "../models";

@Injectable({
  providedIn: "root"
})
export class BrandService {
  private serviceUrl = "brand";

  constructor(private http: ApiService) {}

  getOwnBrands(): Observable<Brand[]> {
    return this.http.get(`${this.serviceUrl}/own`);
  }
}
