import { Injectable } from "@angular/core";
import { ApiService } from "clearline-api";
import { Observable } from "rxjs";
import { CfsMenuDto } from "../models";

@Injectable({ providedIn: "root" })
export class CfsService {
  serviceUrl = "cfs";

  constructor(private http: ApiService) {}

  getCfsMenuList(locationId: number, cashRegisterId: number): Observable<CfsMenuDto> {
    return this.http.get(`${this.serviceUrl}/menu?locationId=${locationId}&cashRegisterId=${cashRegisterId}`);
  }
}
