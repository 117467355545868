import { Injectable } from "@angular/core";
import { CashRegister, CashRegisterPairingStatusPostDto, CashRegisterStatusPostDto } from "cfs-communication-pack";
import { ApiService } from "clearline-api";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class CashRegisterService {
  serviceUrl = "cashregister";

  constructor(private http: ApiService) {}

  getById(id: number): Observable<CashRegister> {
    return this.http.get(`${this.serviceUrl}/${id}`);
  }

  setCashRegisterStatus(id: number, data: CashRegisterStatusPostDto): Observable<null> {
    return this.http.post(`${this.serviceUrl}/${id}/status`, data);
  }

  setCashRegisterPairingStatus(id: number, data: CashRegisterPairingStatusPostDto): Observable<null> {
    return this.http.post(`${this.serviceUrl}/${id}/status/pairing`, data);
  }

  updateCashRegister(cashregister: CashRegister): Observable<CashRegister> {
    return this.http.put(`${this.serviceUrl}/${cashregister.id}`, cashregister);
  }
}
