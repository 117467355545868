import { Injectable } from "@angular/core";
import { ApiService } from "clearline-api";
import { Observable } from "rxjs";
import { TemplateActivityPostDto } from "../models";

@Injectable({
  providedIn: "root"
})
export class ActivityLogService {
  serviceUrl = "activityLog";
  constructor(private http: ApiService) {}

  logTemplatesActivity(templateActivity: TemplateActivityPostDto[]): Observable<void> {
    return this.http.post(`${this.serviceUrl}/templates-activity`, templateActivity);
  }
  logTemplateActivity(templateActivity: TemplateActivityPostDto): Observable<string> {
    return this.http.post(`${this.serviceUrl}/template-activity`, templateActivity);
  }
  logNavigateTemplateActivity(templateActivityId: string): Observable<void> {
    return this.http.post(`${this.serviceUrl}/template-activity/${templateActivityId}/navigate`);
  }
}
