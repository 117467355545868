import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";
import { IframeMessageData, IframeMessageTypes } from "@app-cfs/shared/models";
import { ConsoleLogHandlerService } from "@app-cfs/shared/services";

@Component({
  selector: "app-iframe",
  templateUrl: "./iframe.component.html",
  styleUrls: ["./iframe.component.scss"]
})
export class IframeComponent implements OnDestroy {
  @Input() iframeUrl!: SafeResourceUrl;
  @Output() onLoad = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() onUserActivity = new EventEmitter();
  iframeWindow: Window | null = null;

  private messageEventHandler: (event: MessageEvent) => void;

  constructor(private consoleLogHandler: ConsoleLogHandlerService) {
    this.messageEventHandler = this.iframePostMessageReceiver.bind(this);
  }

  onLoadHandler(iframe: HTMLIFrameElement) {
    if (!iframe.src) return; // Skip initial hit.
    this.consoleLogHandler.log("~iFrame Load", {
      src: iframe.src,
      iframe,
      contentDocument: iframe.contentDocument,
      window: iframe.contentWindow
    });
    this.iframeWindow = iframe.contentWindow;

    window.addEventListener("message", this.messageEventHandler, false);
    this.postIframeMessage(IframeMessageTypes.init, { projectName: "cfs" });
    this.onLoad.emit();
  }
  iframePostMessageReceiver(event: MessageEvent) {
    const eventData: IframeMessageData = event.data;
    this.consoleLogHandler.log(`~iFrame Message: `, event.data);

    switch (eventData.type) {
      case IframeMessageTypes.formSubmited:
        this.onClose.emit();
        break;
      case IframeMessageTypes.userActivity:
        this.onUserActivity.emit();
        break;

      default:
        break;
    }
  }
  postIframeMessage(type: IframeMessageTypes, data: {}) {
    if (this.iframeWindow) {
      const messageData: IframeMessageData = { type, data };
      this.iframeWindow.postMessage(messageData, "*");
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener("message", this.messageEventHandler);
  }
}
