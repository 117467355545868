import { Injectable } from "@angular/core";
import { LocalStorageService } from "@app-cfs/core/services";

@Injectable({
  providedIn: "root"
})
export class CacheService {
  constructor(private localStorageService: LocalStorageService) {}

  clear(): void {
    this.localStorageService.clearAll();
    this.clearSessionStorage();
    this.clearCookies();
  }

  private clearSessionStorage(): void {
    sessionStorage.clear();
  }

  private clearCookies(): void {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
}
