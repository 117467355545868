import { SafeHtml } from "@angular/platform-browser";
import { KeyValueAsset } from "@app-cfs/shared/models/key-value";
import { MediaContent } from "@app-cfs/shared/models/media-content";
import { DigitalAsset, ScreenSizeType } from "./digital-asset";

export enum DefaultTemplateType {
  None = 0,
  Cfs = 1,
  Print = 2,
  CfsAndPrint = 3
}

export enum GlobalTemplateType {
  System = "System",
  Custom = "Custom",
  Global = "Global"
}

export type HtmlTemplateView = ScreenSizesJsonAsset;

export interface HtmlTemplate {
  views: HtmlTemplateView;
  templateType: DefaultTemplateType; // check this props
  displayTime: number;
}

export interface Template {
  id?: number;
  templateId: number;
  name: string;
  templateName?: string;
  widgetId?: number;
  defaultTemplateId?: number;
  templateType?: DefaultTemplateType;
  globalTemplateType: GlobalTemplateType;
  isDefault?: boolean;
  canEnable?: boolean;
  enabled: boolean;
  templateProperties?: TemplateProperties;
  configuration?: TemplateConfigurationDto;
  generatedBody?: string | SafeHtml;
  previewOptions?: TemplatePreviewOptions;
  redirectSettings?: TemplateInteractionRedirectConfigModel;
}

export interface TemplateInteractionRedirectConfigModel {
  redirectPageUrl?: string;
  timeoutInSeconds: number;
}

export interface ContentSettings {
  assetId?: number;
  isPredefined: boolean;
  templateCategory: string;
  displayContent: string;
  backgroundColor: string;
  buttonColor: string;
  campaignSchedule: string;
}
export interface CouponContentSettings {
  CouponUrl: string;
  CouponName: string;
  CouponId: string;
}
export interface TemplateExtendedProperties {
  LottieJson?: string;
  CouponName?: string;
  ShortLinkUrl?: string;
  LocationId?: string;
}
export interface UrlContentSettings {
  ContentType: CustomContentTemplateType;
  ImageUrl: string;
  VideoUrl?: string;
}

export type TemplateProperties = ContentSettings | UrlContentSettings | CouponContentSettings | TemplateExtendedProperties | null;

export interface TemplateConfigurationDto {
  mediaContentId?: number;
  defaultParameters: KeyValueAsset;
  options?: TemplateConfigurationOptionsDto;
  triggers?: TemplateConfigurationTriggerDto[];
}
export interface TemplateConfigurationOptionsDto {
  defaultDestination?: TemplateDestinationDto;
  displayTime?: number;
}
export interface TemplateConfigurationTriggerDto {
  triggerType: TemplateConfigurationTriggerType;
  area: TemplateConfigurationTriggerAreaDto;
  action: TemplateConfigurationTriggerActionDto;
}
export interface TemplateConfigurationTriggerAreaDto {
  areaType: TemplateConfigurationTriggerAreaType;
}
export interface TemplateConfigurationTriggerActionDto {
  actionType: TemplateConfigurationTriggerActionType;
  destination: TemplateCustomDestinationDto;
}
export interface TemplateDestinationDto {
  destinationType: TemplateDestinationType;
  url?: string;
  widgetType?: number;
}
export interface TemplateCustomDestinationDto {
  useDefaultDestination: boolean;
  customDestination?: TemplateDestinationDto;
}
export enum TemplateConfigurationTriggerAreaType {
  Any = "Any"
}
export enum TemplateConfigurationTriggerType {
  None = "None",
  Click = "Click"
}
export enum TemplateConfigurationTriggerActionType {
  IframeNavigation = "IframeNavigation"
}

export interface TemplatesCarouselData {
  templateList?: Template[];
  withPause?: boolean;
  updateOnNewCircle?: boolean;
  forceDisplay?: boolean;
}

export interface TemplateBasePreviewOptions {
  digitalAsset?: DigitalAsset;
  json?: string;
  screenSizesJsonAsset?: ScreenSizesJsonAsset;
  mediaContent?: MediaContent;
}

export interface TemplatePreviewOptions extends TemplateBasePreviewOptions {
  contentSettings?: ContentSettings;
  defaultParameters?: KeyValueAsset;
}

export enum AssetParamKeys {
  MainBackgroundColor = "%%MAIN_BG_COLOR%%",
  ButtonBackgroundColor = "%%BUTTON_BG_COLOR%%",
  Param1 = "%%PARAM_1%%",
  Param2 = "%%PARAM_2%%",
  Param3 = "%%PARAM_3%%",
  Param4 = "%%PARAM_4%%",
  Param5 = "%%PARAM_5%%",
  Param6 = "%%PARAM_6%%"
}

export enum ContentSettingKeys {
  BackgroundColor = "backgroundColor",
  ButtonColor = "buttonColor",
  CampaignSchedule = "campaignSchedule",
  TemplateCategory = "templateCategory"
}

export type ScreenSizesJsonAsset = {
  [size in ScreenSizeType]?: string;
};

export enum TemplateDestinationType {
  Url = "Url",
  Widget = "Widget"
}

export enum CustomContentTemplateType {
  Image = "Image",
  Video = "Video"
}
export enum TemplatePreviewType {
  Active = "Active",
  Inactive = "Inactive"
}
export enum CFSTemplateType {
  Lottie = "lottie",
  Video = "video",
  Image = "image"
}
