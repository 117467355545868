import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MediaContent, MediaContentType } from "@app-cfs/shared/models/media-content";
import { ApiService } from "clearline-api";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class MediaContentService {
  private serviceUrl = "mediaContent";

  constructor(private http: ApiService, public https: HttpClient) {}

  public getMediaContent(id: number): Observable<MediaContent> {
    return this.http.get(`${this.serviceUrl}/${id}`);
  }

  public getByType(type: MediaContentType): Observable<MediaContent[]> {
    return this.http.get(`${this.serviceUrl}/type/${type}`);
  }
}
