import { Injectable } from "@angular/core";
import { ApiService } from "clearline-api";
import { Observable } from "rxjs";
import { Account } from "../models";

@Injectable({
  providedIn: "root"
})
export class CurrentAccountService {
  constructor(private http: ApiService) {}

  getCurrentAccount(): Observable<Account> {
    return this.http.get(`account/current`);
  }
}
