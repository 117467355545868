import { Injectable } from "@angular/core";
import { WebService } from "@app-cfs/core/services";
import { CfsTemplateService } from "@app-cfs/shared/services/cfs-template.service";
import { SettingsHandlerService } from "@app-cfs/shared/services/settings-handler.service";
import { TemplatesService } from "@app-cfs/shared/services/templates.service";
import { TranslationService } from "@app-cfs/shared/services/translation.service";
import { CFSConnectionMessage } from "cfs-communication-pack";
import { BehaviorSubject, combineLatest, Observable, of, Subject } from "rxjs";
import { catchError, distinctUntilChanged, filter, map, mergeMap, take, tap } from "rxjs/operators";
import { ButtonViewType, CarouselUserAction, CarouselUserActionType, CfsMenuDto, Template, TemplatesCarouselData } from "../models";
import { CfsService } from "./cfs.service";

export interface ForceCarouselSlideInfo {
  timeout?: number;
  accountTemplateId?: number;
  widgetId?: number;
  sessionId?: string;
  shortLink?: string;
  couponName?: string;
}

export interface CarouselSlideInfo extends ForceCarouselSlideInfo {
  templateList: Template[];
}

@Injectable({
  providedIn: "root"
})
export class CarouselService {
  constructor(
    private settingsHandler: SettingsHandlerService,
    private templatesService: TemplatesService,
    private cfsTemplateService: CfsTemplateService,
    private cfsService: CfsService,
    private translationService: TranslationService,
    private webService: WebService
  ) {}

  private readonly _defaultSlideIndex = 0;

  private _savedSlideIndex = this._defaultSlideIndex;
  private templateList: Template[] | null = null;
  private preparedTemplateList: Template[] | null = null;
  private forcedTemplatesList: CarouselSlideInfo[] = [];

  private userActionSrc$ = new Subject<CarouselUserAction>();
  readonly userAction$ = this.userActionSrc$.asObservable();

  private inProgress$ = new BehaviorSubject<boolean>(true);
  readonly progressStatus$ = this.inProgress$.pipe(distinctUntilChanged());

  private currentData = new BehaviorSubject<TemplatesCarouselData | null>(null);
  readonly currentData$: Observable<TemplatesCarouselData | null> = this.currentData.asObservable();

  private menuInfoSrc = new BehaviorSubject<CfsMenuDto | null>(null);
  readonly menuInfo$: Observable<CfsMenuDto | null> = this.menuInfoSrc.asObservable();

  private updateMenuSrc = new BehaviorSubject<boolean>(false);
  readonly updateMenu$ = this.updateMenuSrc.asObservable().pipe(filter((value) => value));

  private templateSessionExpiredSrc = new Subject<string>();
  readonly templateSessionExpired$ = this.templateSessionExpiredSrc.asObservable();

  get defaultSlideIndex(): number {
    return this._defaultSlideIndex;
  }

  get savedSlideIndex(): number {
    return this._savedSlideIndex;
  }

  saveSlideIndex(index: number): void {
    this._savedSlideIndex = index;
  }

  pauseCarousel(): void {
    this.inProgress$.next(false);
  }

  playCarousel(): void {
    this.inProgress$.next(true);
  }

  sendUserAction(action: CarouselUserAction): void {
    this.userActionSrc$.next(action);
  }

  runUpdateMenu(): void {
    this.updateMenuSrc.next(true);
  }

  updateCarouselData(data: TemplatesCarouselData): void {
    this.currentData.next(data);
  }

  displayCarousel(withPause: boolean = false, updateOnNewCircle: boolean = false, forceUpdate: boolean = false): Observable<Template[]> {
    const templateList: Template[] = this.settingsHandler.templateList;
    const isListUpdated: boolean = JSON.stringify(templateList) !== JSON.stringify(this.templateList);

    if (isListUpdated || forceUpdate) {
      const preparedTemplateList$: Observable<Template[]> = this.getPreparedTemplateList();

      return preparedTemplateList$.pipe(
        tap((preparedTemplates: Template[]) => {
          this.templateList = templateList;
          this.preparedTemplateList = preparedTemplates;

          this.updateCarouselData({
            templateList: preparedTemplates,
            withPause,
            updateOnNewCircle,
            forceDisplay: false
          });
        })
      );
    }

    return of([]);
  }

  displayForcedCarousel(slideInfo: ForceCarouselSlideInfo): Observable<Template[]> {
    const { sessionId } = slideInfo;
    const timeout: number = slideInfo.timeout || 0;

    return this.cfsTemplateService.getForceTemplateList(slideInfo).pipe(
      catchError(() => of(null)),
      mergeMap((templateList) => {
        if (!templateList?.length) return of([]);

        return this.templatesService.getPreparedTemplates$(templateList).pipe(
          tap((preparedTemplateList: Template[]) => {
            const preparedTemplate: Template = preparedTemplateList?.[0] || null;

            if (preparedTemplate?.configuration?.options) {
              preparedTemplate.configuration.options.displayTime = timeout * 1000;

              this.forcedTemplatesList.push({ templateList: preparedTemplateList, timeout, sessionId });

              if (preparedTemplateList?.length) {
                this.updateCarouselData({
                  templateList: preparedTemplateList,
                  withPause: false,
                  updateOnNewCircle: false,
                  forceDisplay: true
                });
              }
            }
          })
        );
      })
    );
  }

  updateForcedCarousel(): void {
    this.forcedTemplatesList.pop();

    if (this.forcedTemplatesList.length) {
      const lastForceTemplateData: CarouselSlideInfo = this.forcedTemplatesList[this.forcedTemplatesList.length - 1];
      const { templateList } = lastForceTemplateData;

      this.updateCarouselData({
        templateList,
        withPause: false,
        updateOnNewCircle: false,
        forceDisplay: true
      });
    } else {
      this.displayCarousel(false, false, true).pipe(take(1)).subscribe();
    }
  }

  onForcedCarouselTimeout(): void {
    const currentSlideInfo: CarouselSlideInfo | null = this.getCurrentSlideInfo();
    const slideSessionId: string | undefined = currentSlideInfo?.sessionId;

    if (slideSessionId) {
      this.templateSessionExpiredSrc.next(slideSessionId);
    }
  }

  onQrCodeScanned(message: CFSConnectionMessage): void {
    const { sessionId } = message.qrCodeScannedInfo || {};
    const currentSlideInfo: CarouselSlideInfo | null = this.getCurrentSlideInfo();
    const currentTemplate: Template | undefined = currentSlideInfo?.templateList?.[0];
    const slideSessionId: string | undefined = currentSlideInfo?.sessionId;

    if (currentTemplate?.redirectSettings?.redirectPageUrl && sessionId === slideSessionId) {
      const { redirectPageUrl, timeoutInSeconds } = currentTemplate.redirectSettings;
      const destinationUrl: string = this.webService.getFullUrl(redirectPageUrl);
      const timeoutRedirect: number = timeoutInSeconds ? timeoutInSeconds * 1000 : 0;

      this.sendUserAction({
        actionType: CarouselUserActionType.templateAction,
        redirectUrl: destinationUrl,
        timeoutRedirect,
        confirmActivity: false,
        trackActivity: false,
        closeBtnViewType: ButtonViewType.Cross
      });
      this.updateForcedCarousel();
    }
  }

  onQrCodeHidden(message: CFSConnectionMessage): void {
    const { sessionId } = message.hideQrCodeInfo || {};
    const currentSlideInfo: CarouselSlideInfo | null = this.getCurrentSlideInfo();
    const slideSessionId: string | undefined = currentSlideInfo?.sessionId;

    if (sessionId === slideSessionId) {
      this.sendUserAction({ actionType: CarouselUserActionType.goBack });
      this.updateForcedCarousel();
    }
  }

  getCurrentSlideInfo(): CarouselSlideInfo | null {
    if (this.forcedTemplatesList.length) {
      return this.forcedTemplatesList[this.forcedTemplatesList.length - 1] || null;
    }

    const mainTemplate: Template | null = this.templateList?.[this._savedSlideIndex] || null;

    return mainTemplate ? { templateList: [mainTemplate] } : null;
  }

  getCfsMenuList(): Observable<CfsMenuDto> {
    const { locationId, cashRegister } = this.settingsHandler;

    return this.cfsService.getCfsMenuList(locationId, cashRegister).pipe(
      tap((menuInfo: CfsMenuDto) => {
        this.menuInfoSrc.next(menuInfo);
      })
    );
  }

  private getPreparedTemplateList(): Observable<Template[]> {
    const templateList: Template[] = this.settingsHandler.templateList;

    if (this.isCarouselPrepared(templateList)) {
      return of(this.preparedTemplateList as Template[]);
    }

    return combineLatest([
      this.templatesService.getPreparedTemplates$(templateList?.length ? templateList : []),
      this.translationService.ready$
    ]).pipe(map(([preparedTemplates]) => preparedTemplates));
  }

  private isCarouselPrepared(templateList: Template[]): boolean {
    if (this.preparedTemplateList?.length) {
      const getTemplateWithPreviewReset = (item: Template) => {
        return { ...item, previewOptions: undefined };
      };
      const handledTemplateList: Template[] = templateList.map(getTemplateWithPreviewReset);
      const handledPreparedTemplateList: Template[] = this.preparedTemplateList.map(getTemplateWithPreviewReset);

      return JSON.stringify(handledTemplateList) === JSON.stringify(handledPreparedTemplateList);
    }

    return false;
  }
}
